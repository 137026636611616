import { bigMath } from '../misc';
import { getPaymentTitle } from '../order';
import {
	PaymentsSummary,
	QueryGetPrintableReportsOverallArgs,
	SalesSummary,
	SalesSummaryByItems,
	SalesSummaryByLocations,
} from '../types/__generated/gql/graphql';
import { toVenueTime, venueDataHelper, VenueDataHelperParameter } from '../venue';

import { getStyleFragment, GetStyleFragmentParameters } from './fragments/get-style-fragment';

type GenerateOverallReportsOpts = Pick<QueryGetPrintableReportsOverallArgs, 'from' | 'to' | 'shift'> & {
	salesSummaryOverall: SalesSummary;
	itemsSummary: SalesSummaryByItems;
	locationsSummary: SalesSummaryByLocations;
	receiptsSummary: PaymentsSummary;
	venue: GetStyleFragmentParameters &
		VenueDataHelperParameter & {
			timezone: string;
			name: string;
		};
	selectedPaymentTypes: string[];
	selectedLocationGroupTags: string[];
	onlyItemsWithSales: boolean;
};

const generateOverallReportsTemplate = ({
	from,
	to,
	shift,
	salesSummaryOverall,
	itemsSummary,
	locationsSummary,
	receiptsSummary,
	venue,
	selectedPaymentTypes,
	selectedLocationGroupTags,
	onlyItemsWithSales,
}: GenerateOverallReportsOpts) => {
	const { $d } = venueDataHelper(venue);

	const filteredPaymentTypes = Object.keys(receiptsSummary.byPaymentTypes)
		.filter((k) => selectedPaymentTypes.includes(k))
		.filter((k) => (onlyItemsWithSales ? !!receiptsSummary.byPaymentTypes[k] : true));
	const filteredLocationGroupTags = locationsSummary.byLocationGroupTags
		.filter((summary) => selectedLocationGroupTags.includes(summary.tag))
		.filter((summary) => (onlyItemsWithSales ? !!summary.salesSummary.grossSales.total : true));
	const filteredLocationGroupTagsSumUpAmount = filteredLocationGroupTags.reduce(
		(pre, cur) => bigMath.add(pre, cur.salesSummary.grossSales.total),
		0
	);
	const filteredItemTypes = Object.keys(itemsSummary.grossSales.byTypes).filter((k) =>
		onlyItemsWithSales ? !!itemsSummary.grossSales.byTypes[k] : true
	);

	return /* HTML */ `
		<html>
			<head>
				${getStyleFragment(venue, 'reports')}
			</head>
			<body>
				<div class="container">
					<div class="centered text-xl light-bold my-1">Sales Summary Reports</div>
					<div class="centered light-bold my-1">${venue.name}</div>
					<br />
					<div class="text-sm">
						Date From:
						<span class="float-right pr-2">
							${toVenueTime(from, venue)}
						</span>
					</div>
					<div class="text-sm mt-1">
						Date To:
						<span class="float-right pr-2">${toVenueTime(to, venue)}</span>
					</div>
					${
						shift
							? `
                <div class="text-sm mt-1">
                  Selected Shift: <span class="float-right pr-2">${shift}</span>
                </div>
              `
							: ''
					}

					<br />

					<div class="text-md mt-1">
						Sales Summary <span class="float-right pr-2">${$d(salesSummaryOverall.grossSales.total)}</span>
					</div>
					<hr class="divider" />
					<div class="text-sm">
						Gross Sales: <span class="float-right pr-2">${$d(salesSummaryOverall.grossSales.total)}</span>
					</div>
					<div class="text-sm">
						Net Sales: <span class="float-right pr-2">${$d(salesSummaryOverall.netSales.total)}</span>
					</div>
					<div class="text-sm">
						VAT: <span class="float-right pr-2">${$d(salesSummaryOverall.vat.total)}</span>
					</div>
					<div class="text-sm">
						Service Charge:
						<span class="float-right pr-2">${$d(salesSummaryOverall.serviceCharge.total)}</span>
					</div>
					<div class="text-sm">
						Discount: <span class="float-right pr-2">${$d(salesSummaryOverall.discount.total)}</span>
					</div>
					<div class="text-sm">
						Offer: <span class="float-right pr-2">${$d(salesSummaryOverall.offer.total)}</span>
					</div>
					<div class="text-sm">
						Discount & Offer:
						<span class="float-right pr-2">
							${$d(bigMath.add(salesSummaryOverall.discount.total, salesSummaryOverall.offer.total))}
						</span>
					</div>
					<div class="text-sm">
						Rounding Amount:
						<span class="float-right pr-2">${$d(receiptsSummary.roundingDifference)}</span>
					</div>
					<div class="text-sm">
						Headcount: <span class="float-right pr-2">${receiptsSummary.headcount}</span>
					</div>

					<br />

					<div class="text-md mt-1">
						Sales By Receipts
						<span class="float-right pr-2">${$d(receiptsSummary.paymentsReceived)}</span>
					</div>
					<hr class="divider" />
					<div class="text-sm">
						Non Void Receipts #:
						<span class="float-right pr-2">${receiptsSummary.receiptsCount.nonVoid}</span>
					</div>
					<div class="text-sm">
						Voided Receipts #: <span class="float-right pr-2">${receiptsSummary.receiptsCount.void}</span>
					</div>
					<div class="text-sm">
						Average Receipt Value:
						<span class="float-right pr-2">${$d(receiptsSummary.averagePaymentPerReceipt)}</span>
					</div>

					<br />

					<div class="text-md mt-1">
						Sales by Payment Types
						<span class="float-right pr-2"
							>${$d(
								filteredPaymentTypes.reduce(
									(pre, cur) => bigMath.add(pre, receiptsSummary.byPaymentTypes[cur] as number | undefined),
									0
								)
							)}</span
						>
					</div>
					<hr class="divider" />
					${filteredPaymentTypes
						.map(
							(k) => `
                <div class="text-sm">
                  ${getPaymentTitle(k, venue)}:
									<span class="float-right pr-2">${$d(receiptsSummary.byPaymentTypes[k] as number)}</span>
                </div>
              `
						)
						.join('\n')}

					<br />

					<div class="text-md mt-1">
						Sales by Item Types
						<span class="float-right pr-2">${$d(salesSummaryOverall.grossSales.total)}</span>
					</div>
					<hr class="divider" />
					${filteredItemTypes
						.map(
							(k) => `
                <div class="text-sm">
                  ${k}: <span class="float-right pr-2">${$d(
								itemsSummary.grossSales.byTypes[k] as number
							)}</span>
                </div>
              `
						)
						.join('\n')}
					${
						locationsSummary.byLocationGroupTags.length
							? `
								<br />

								<div class="text-md mt-1">Sales by Location Groups
								<span class="float-right pr-2">${$d(
									filteredLocationGroupTagsSumUpAmount <= salesSummaryOverall.grossSales.total
										? filteredLocationGroupTagsSumUpAmount
										: salesSummaryOverall.grossSales.total
								)}</span>
								</div>
								<hr class="divider" />
                ${filteredLocationGroupTags
									.map(
										(tSummary) => `
                      <div class="text-sm">
                        ${tSummary._tag.label}:
												<span class="float-right pr-2">${$d(tSummary.salesSummary.grossSales.total)}</span>
                      </div>
                    `
									)
									.join('\n')}
              `
							: ''
					}

					<br />

					<div class="text-md mt-1">
						Cancellations <span class="float-right pr-2">${$d(itemsSummary.cancelled.value)}</span>
					</div>
					<hr class="divider" />
					<div class="text-sm">
						Items Value:
						<span class="float-right pr-2">${$d(itemsSummary.cancelled.value)}</span>
					</div>
					<div class="text-sm">
						Items Count:
						<span class="float-right pr-2">${itemsSummary.cancelled.count}</span>
					</div>
				</div>
			</body>
		</html>
	`
		.replace(/<!--\s*?[^\s?[][\s\S]*?-->/g, '')
		.replace(/>\s*</g, '><')
		.trim();
};

export { generateOverallReportsTemplate };
