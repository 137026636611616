import { CircularProgress, Paper, Snackbar, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useBoolean } from 'usehooks-ts';

import { Dialog } from '~served/ui-comps';

import { useIsLatest, useSetIsLatest } from '~/store';

declare global {
	interface Window {
		workbox: {
			messageSkipWaiting(): void;
			register(): void;
			addEventListener(
				name: string,
				callback: (e: { type: string; payload: any; isUpdate: boolean }) => unknown
			);
		};
	}
}

const AppUpdateChecker = () => {
	const { value: isDownloading, toggle: toggleIsDownloading } = useBoolean(false);
	const isLatest = useIsLatest();
	const setIsLatest = useSetIsLatest();

	// https://developer.chrome.com/docs/workbox/modules/workbox-window#type-WorkboxLifecycleEventMap
	useEffect(() => {
		if ('serviceWorker' in navigator && window.workbox !== undefined) {
			const wb = window.workbox;

			const handleLog = console.log;

			wb.addEventListener('installing', handleLog);
			wb.addEventListener('message', handleLog);
			wb.addEventListener('installed', (e) => {
				handleLog(e);
				if (e.isUpdate) toggleIsDownloading();
			});
			wb.addEventListener('redundant', handleLog);
			wb.addEventListener('activating', handleLog);
			wb.addEventListener('controlling', handleLog);
			wb.addEventListener('waiting', handleLog);
			wb.addEventListener('activated', (e) => {
				handleLog(e);
				if (e.isUpdate) {
					toggleIsDownloading();
					setIsLatest(false);
				}
			});

			wb.register();
		}
	}, []);

	return (
		<>
			{isDownloading && (
				<Snackbar open>
					<Paper variant="elevation" elevation={12} sx={{ p: 2, display: 'flex', gap: 1 }}>
						<CircularProgress size={24} />
						<Typography variant="body2">Installing updates</Typography>
					</Paper>
				</Snackbar>
			)}

			{!isLatest && (
				<Dialog
					dialogProps={{ maxWidth: 'xs' }}
					title="🎉 Newer version of the app is available"
					actionButtonProps={{
						children: 'Reload the page',
						onClick: () => window.location.reload(),
					}}
				/>
			)}
		</>
	);
};

export { AppUpdateChecker };
