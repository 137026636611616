import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Dialog } from '~served/ui-comps';

import { useSendAssistance } from '~/queries/useSendAssistance';
import { useIsNeededHelp, useSetIsNeededHelp } from '~/store';

const AssistanceDialog = () => {
	const form = useFormik({
		validateOnMount: true,
		initialValues: {
			content: '',
		},
		initialStatus: {},
		validationSchema: Yup.object().shape({
			content: Yup.string().max(60, 'Max 60 characters'),
		}),
		onSubmit: async ({ content }, helpers) => {
			await sendAssistance.mutateAsync({ input: { content } });
			helpers.resetForm();
			handleClose();
		},
	});

	const isNeededHelp = useIsNeededHelp();
	const setIsNeededHelp = useSetIsNeededHelp();
	const sendAssistance = useSendAssistance();

	const handleClose = () => setIsNeededHelp(false);

	if (!isNeededHelp) return null;

	return (
		<Dialog
			dialogProps={{ maxWidth: 'xs' }}
			title="Need assistance from staff?"
			content={
				<TextField
					name="content"
					label="Need help?"
					value={form.values.content}
					error={!!form.errors.content}
					helperText={form.errors.content}
					onBlur={form.handleBlur}
					onChange={form.handleChange}
				/>
			}
			cancelButtonProps={{ children: 'Cancel', onClick: handleClose }}
			actionButtonProps={{
				children: 'Call Staff',
				onClick: () => form.handleSubmit(),
				disabled: !form.isValid,
				loading: sendAssistance.isLoading,
			}}
		/>
	);
};

export { AssistanceDialog };
